html,
body {
  font-family: "Google Sans", sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f7f8fc !important;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  /* background-color: #fff !important; */
}

.wb-custom-style {
  /* background: linear-gradient(310deg, rgb(41 228 127), rgb(42 144 229)); */
  background: linear-gradient(90deg, #ba8edc, #f377ab, #9795ff, #5756a5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.wb-container-style {
  width: 100%;
  margin-left: auto;
  box-sizing: border-box;
  margin-right: auto;
  display: block;
  padding-left: 16px;
  padding-right: 16px;
  min-height: 500px;
  height: calc(100vh - 120px);
  transition: all 0.3s ease 0s;
}

.wb-box-style {
  width: 40px;
  height: 40px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-radius: 12px;
  border: 1px solid;
  border-color: #99ccf3;
  background-color: #f0f7ff;
  box-shadow: 0px 1px 6px 0px rgba(194, 224, 255, 1),
    0px 2px 30px 0px rgba(234, 237, 241, 0.3) inset;
}

.rbc-header {
  /* background: #79b8e4; */
  /* color: white; */
  /* padding-bottom: 5px; */
  font-size: 12px;
}

.rbc-event {
  padding: 4px !important;
  border-radius: 0px !important;
  /* border: none !important; */
  /* background-color: transparent !important; */
  /* z-index: 2; */
  position: relative;
}

.rbc-background-event {
  padding: 0px !important;
  border-radius: 0px !important;
  border: none !important;
  background-color: transparent !important;
}

/* .rbc-event-label {
  display: none !important;
} */

.rbc-events-container {
  width: 100% !important;
}

/* .rbc-timeslot-group {
  min-height: 70px !important;
} */

/* .rbc-day-slot .rbc-time-slot:after {
  content: "";
  width: 100%;
  border-top: 1px solid lightblue;
  display: block;
} */

.rbc-addons-dnd-resizable {
  height: 100%;
  width: 100%;
}

/* Updated CSS for styling FullCalendar button group */
.fc-button-group {
  display: flex;
  border: 1px solid #ccc !important; /* Set the border color */
  border-radius: 15px; /* Set the border radius */
  overflow: hidden;
}

.fc-button {
  flex: 1 !important;
  border: none !important;
  width: "10rem !important";
  background-color: transparent !important ;
  border-radius: 0 !important; /* Reset the button border radius */
  color: black !important; /* Set the text color for transparent buttons */
}

.fc-today-button {
  font-weight: 500 !important;
  font-size: 0.9125rem !important;
  line-height: 1.2 !important;
  text-transform: capitalize !important;
}
.fc-button-primary {
  font-weight: 500 !important;
  font-size: 0.9125rem !important;
  padding: 4px 8px !important;
  margin: 3px !important;
  line-height: 1.2 !important;
  width: auto !important;
  flex: 1 1 auto !important;
}

/* Add more styles as needed for the active state and hover state */
.fc-button-active {
  background-color: #f0f0f0 !important;
  color: rgb(21, 12, 12) !important;
  border-radius: 30rem !important;
  background-color: #e8e8e9 !important;
  box-shadow: none !important;
}

.fc-prev-button {
  box-shadow: none !important;
  padding: 4px 8px !important;
  margin: 3px !important;
  line-height: 1.2 !important;
}
.fc-next-button {
  box-shadow: none !important;
  padding: 4px 8px !important;
  margin: 3px !important;
  line-height: 1.2 !important;
}

.fc-button:hover {
  background-color: #f0f0f0; /* Change the background color on hover */
}

.fc .fc-button-primary:not(:disabled).fc-button-active:focus,
.fc .fc-button-primary:not(:disabled):active:focus {
  box-shadow: none !important;
}

.fc-day:hover {
  cursor: pointer;
}

.fc-day-sun {
  background-color: rgba(199, 198, 198, 0.118);
}
.fc-day-sat {
  background-color: rgba(199, 199, 198, 0.118);
}


.ql-container {
  min-height: 5em;
  z-index: 0;
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  background: #94949408;
}

.ql-toolbar {
  background: #eaecec;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
  border-bottom: none;
}
